import { gql } from '@/shared/graphql/generated/lp';

export const getLatestEventQuery = gql(/* GraphQL */ `
  query GetLatestEvent {
    allEvents(first: 1, orderBy: BLOCK_DESC) {
      nodes {
        timestamp
      }
    }
  }
`);
