import { type SwapFee } from './graphql/generated/explorer/graphql';

export const TABLE_POLL_INTERVAL = 10_000;

export const NETWORK_FEE = 'Network fee';
export const POOL_FEE = 'Pool fee';
export const INGRESS_FEE = 'Deposit fee';
export const EGRESS_FEE = 'Broadcast fee';
export const BROKER_FEE = 'Broker fee';
export const BOOST_FEE = 'Boost fee';

export const feeTypeMap: Record<SwapFee['type'], string> = {
  BROKER: BROKER_FEE,
  NETWORK: NETWORK_FEE,
  BOOST: BOOST_FEE,
  INGRESS: INGRESS_FEE,
  EGRESS: EGRESS_FEE,
  LIQUIDITY: POOL_FEE,
};

export const FIRST_SWAP_DATE = '2023-12-19';
