import { gql } from '@/shared/graphql/generated/lp';

export const getBoostPoolLiquidityBalanceChangeQuery = gql(/* GraphQL */ `
  query GetBoostPoolLiquidityBalanceChange(
    $asset: ChainflipAsset!
    $feeTierPips: Int!
    $first: Int
    $offset: Int
  ) {
    pool: boostPoolByAssetAndFeeTierPips(asset: $asset, feeTierPips: $feeTierPips) {
      balanceChanges: boostLiquidityBalanceChangesByBoostPoolId(
        filter: { type: { in: [FUNDS_ADDED, WITHDRAWAL, DELAYED_WITHDRAWAL, FUNDS_LOST] } }
        orderBy: EVENT_ID_DESC
        first: $first
        offset: $offset
      ) {
        edges {
          node {
            amount
            amountUsd
            type
            event: eventByEventId {
              block
              indexInBlock
              timestamp
            }
            lp: accountByLiquidityProviderId {
              idSs58
            }
            pool: boostPoolByBoostPoolId {
              asset
              feeTierPips
            }
          }
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`);

export const getBoostSharesByPoolQuery = gql(/* GraphQL */ `
  query GetBoostSharesByPool($asset: ChainflipAsset!, $feeTierPips: Int!) {
    pools: allBoostPools(condition: { asset: $asset, feeTierPips: $feeTierPips }) {
      nodes {
        boostSummaries: liquidityProviderBoostPoolSummariesByBoostPoolId {
          nodes {
            feeUsd
            fee
            amountUsd
            amount
            account: accountByLiquidityProviderId {
              id
              idSs58
            }
          }
        }
      }
    }
  }
`);

export const getPoolBoostSharesQuery = gql(/* GraphQL */ `
  query GetPoolBoosts(
    $asset: ChainflipAsset!
    $feeTierPips: Int!
    $first: Int
    $offset: Int
    $orderBy: [BoostPoolBoostSharesOrderBy!]
  ) {
    pool: boostPoolByAssetAndFeeTierPips(asset: $asset, feeTierPips: $feeTierPips) {
      boostShares: boostPoolBoostSharesByBoostPoolId(
        orderBy: $orderBy
        first: $first
        offset: $offset
      ) {
        edges {
          node {
            id
            amount
            amountUsd
            fee
            feeUsd
            lost
            boost: boostByBoostId {
              swapRequestId
              event: eventByExecutedAtEventId {
                indexInBlock
                block
                timestamp
              }
              channel: depositChannelByDepositChannelId {
                issuedBlockId
                chain
                channelId
                type
              }
            }
            pool: boostPoolByBoostPoolId {
              asset
              feeTierPips
            }
          }
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`);

export const getAllBoostPoolIdsQuery = gql(/* GraphQL */ `
  query LpBoostPoolIds {
    pools: allBoostPools {
      nodes {
        id
        asset
        feeTierPips
      }
    }
  }
`);

export const getBoostSharesByAccountAndPoolQuery = gql(/* GraphQL */ `
  query GetBoostSharesByAccountAndPool(
    $accountId: String!
    $boostPoolId: Int
    $first: Int
    $offset: Int
    $orderBy: [BoostSharesOrderBy!]
  ) {
    account: accountByIdSs58(idSs58: $accountId) {
      shares: boostSharesByLiquidityProviderId(
        condition: { boostPoolId: $boostPoolId }
        orderBy: $orderBy
        first: $first
        offset: $offset
      ) {
        edges {
          node {
            id
            amount
            amountUsd
            fee
            feeUsd
            lost
            boost: boostByBoostId {
              swapRequestId
              event: eventByExecutedAtEventId {
                indexInBlock
                block
                timestamp
              }
              channel: depositChannelByDepositChannelId {
                issuedBlockId
                chain
                channelId
                type
              }
            }
            pool: boostPoolByBoostPoolId {
              asset
              feeTierPips
            }
          }
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`);

export const getAccountBoostLiquidityBalanceChangesQuery = gql(/* GraphQL */ `
  query AccountBoostLiquidityBalanceChanges(
    $accountId: String!
    $boostPoolId: Int
    $first: Int
    $offset: Int
  ) {
    account: accountByIdSs58(idSs58: $accountId) {
      balanceChanges: boostLiquidityBalanceChangesByLiquidityProviderId(
        filter: { type: { in: [FUNDS_ADDED, WITHDRAWAL, DELAYED_WITHDRAWAL, FUNDS_LOST] } }
        condition: { boostPoolId: $boostPoolId }
        first: $first
        offset: $offset
        orderBy: EVENT_ID_DESC
      ) {
        edges {
          node {
            amount
            amountUsd
            type
            event: eventByEventId {
              block
              indexInBlock
              timestamp
            }
            lp: accountByLiquidityProviderId {
              idSs58
            }
            pool: boostPoolByBoostPoolId {
              asset
              feeTierPips
            }
          }
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`);
export const getEarnedFeesByPoolForAccount = gql(/* GraphQL */ `
  query EarnedFeesByPoolForAccount($accountId: String!, $since: Datetime) {
    account: accountByIdSs58(idSs58: $accountId) {
      boostShares: boostSharesByLiquidityProviderId(
        filter: { executedAtTimestamp: { greaterThanOrEqualTo: $since }, lost: { equalTo: false } }
      ) {
        groupedAggregates(groupBy: BOOST_POOL_ID) {
          keys
          sum {
            amount
            amountUsd
            fee
            feeUsd
          }
        }
      }
    }
    boostPools: allBoostPools {
      nodes {
        id
        feeTierPips
        asset
      }
    }
  }
`);
export const getEarnedFeesInPoolForAccount = gql(/* GraphQL */ `
  query EarnedFeesInPoolForAccount(
    $idSs58: String!
    $boostPoolId: Int
    $minTimestamp: Datetime!
    $maxTimestamp: Datetime!
    $groupBy: [BoostShareGroupBy!]!
  ) {
    account: accountByIdSs58(idSs58: $idSs58) {
      boostShares: boostSharesByLiquidityProviderId(
        filter: {
          executedAtTimestamp: {
            greaterThanOrEqualTo: $minTimestamp
            lessThanOrEqualTo: $maxTimestamp
          }
          lost: { equalTo: false }
        }
        condition: { boostPoolId: $boostPoolId }
        orderBy: EXECUTED_AT_TIMESTAMP_DESC
      ) {
        groupedAggregates(groupBy: $groupBy) {
          keys
          sum {
            fee
            feeUsd
          }
        }
      }
    }
  }
`);

export const getEarnedFeesByPool24hsForAccount = gql(/* GraphQL */ `
  query EarnedFeesByPool24hsForAccount($accountId: String!, $timestamp: Datetime!) {
    account: accountByIdSs58(idSs58: $accountId) {
      boostShares: boostSharesByLiquidityProviderId(
        filter: {
          executedAtTimestamp: { greaterThanOrEqualTo: $timestamp }
          lost: { equalTo: false }
        }
      ) {
        groupedAggregates(groupBy: BOOST_POOL_ID) {
          keys
          sum {
            fee
            feeUsd
          }
        }
      }
    }
  }
`);

export const getEarnedFeesAndVolumeForPools = gql(/* GraphQL */ `
  query EarnedFeesAndVolumeForPools {
    pools: allBoostPools {
      nodes {
        id
        feeTierPips
        asset
        shares: boostPoolBoostSharesByBoostPoolId(condition: { lost: false }) {
          aggregates {
            sum {
              amount
              amountUsd
              fee
              feeUsd
            }
          }
        }
      }
    }
  }
`);

export const getBoostPoolVolumeAndFeesAggregates = gql(/* GraphQL */ `
  query GetBoostPoolVolumeAndFeesAggregates(
    $boostPoolId: Int!
    $minTimestamp: Datetime!
    $maxTimestamp: Datetime!
    $groupBy: BoostPoolBoostShareGroupBy!
  ) {
    boostPools: allBoostPools(condition: { id: $boostPoolId }) {
      nodes {
        boostShares: boostPoolBoostSharesByBoostPoolId(
          filter: {
            executedAtTimestamp: {
              greaterThanOrEqualTo: $minTimestamp
              lessThanOrEqualTo: $maxTimestamp
            }
            lost: { equalTo: false }
          }
        ) {
          groupedAggregates(groupBy: [$groupBy]) {
            keys
            sum {
              amountUsd
              amount
              fee
              feeUsd
            }
          }
        }
      }
    }
  }
`);

export const getBoostPoolApyQuery = gql(/* GraphQL */ `
  query GetBoostPoolApy(
    $asset: ChainflipAsset!
    $feeTierPips: Int!
    $minTimestamp: Datetime
    $maxTimestamp: Datetime
    $first: Int
  ) {
    boostPool: boostPoolByAssetAndFeeTierPips(asset: $asset, feeTierPips: $feeTierPips) {
      apys: boostPoolApiesByBoostPoolId(
        filter: {
          and: [
            { timestamp: { greaterThanOrEqualTo: $minTimestamp } }
            { timestamp: { lessThanOrEqualTo: $maxTimestamp } }
          ]
        }
        orderBy: BLOCK_DESC
        first: $first
      ) {
        nodes {
          timestamp
          projectedApy
        }
      }
    }
  }
`);

export const getAllBoostPoolApyQuery = gql(/* GraphQL */ `
  query GetAllBoostPoolApy($feeTierPips: Int, $asset: ChainflipAsset) {
    allBoostPools(
      filter: { feeTierPips: { equalTo: $feeTierPips }, and: { asset: { equalTo: $asset } } }
    ) {
      nodes {
        asset
        feeTierPips
        apys: boostPoolApiesByBoostPoolId(orderBy: BLOCK_DESC, first: 1) {
          nodes {
            projectedApy
          }
        }
      }
    }
  }
`);

export const getBoostFeesAggregatedByLp = gql(/* GraphQL */ `
  query GetBoostFeesAggregatedByLp($idSs58: String) {
    lps: allAccounts(condition: { idSs58: $idSs58 }) {
      nodes {
        idSs58
        boostShares: boostSharesByLiquidityProviderId(condition: { lost: false }) {
          aggregates {
            sum {
              feeUsd
            }
          }
        }
      }
    }
  }
`);

export const getLastBoostPoolWithdrawalQuery = gql(/* GraphQL */ `
  query GetLastBoostPoolWithdrawal($boostPoolId: Int!, $accountId: String!) {
    account: accountByIdSs58(idSs58: $accountId) {
      balanceChanges: boostLiquidityBalanceChangesByLiquidityProviderId(
        condition: { type: WITHDRAWAL, boostPoolId: $boostPoolId }
        orderBy: EVENT_ID_DESC
        first: 1
      ) {
        nodes {
          event: eventByEventId {
            timestamp
          }
        }
      }
    }
  }
`);
