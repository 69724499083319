import { chainflipLinks } from '@chainflip/utils/consts';
import classNames from 'classnames';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { Link } from './atoms/Link';
import { BANNER_STATUS_QUERY } from '../graphql/cache/queries';
import { getLatestBlocksQuery } from '../graphql/explorer/queries';
import { type App } from '../graphql/generated/cache/graphql';
import { getLatestEventQuery } from '../graphql/lp/events';
import { InformationIcon, SparklesIcon, WarningTriangleIcon } from '../icons/small';

const LAG_THRESHOLD_MINUTES = 2; // 2 minutes

export default function Banner({ app }: { app: App }): JSX.Element | null {
  const { data } = useGqlQuery(BANNER_STATUS_QUERY, {
    context: { clientName: 'statechainCache' },
    variables: { app },
  });
  const { data: blocksQuery } = useGqlQuery(getLatestBlocksQuery, {
    refetchInterval: 30_000,
    enabled: app === 'EXPLORER',
  });
  const { data: lpEventQuery } = useGqlQuery(getLatestEventQuery, {
    refetchInterval: 30_000,
    enabled: app === 'LP',
    context: { clientName: 'lpProcessor' },
  });

  const latestLpEvent = lpEventQuery?.allEvents?.nodes.at(0)?.timestamp;
  const lpDifference = latestLpEvent ? differenceInMinutes(new Date(), new Date(latestLpEvent)) : 0;

  const isLpProcessorBehind = lpDifference >= LAG_THRESHOLD_MINUTES;

  const latestBlock = blocksQuery?.latestBlocks?.nodes.at(0)?.timestamp;
  const explorerDifference = latestBlock
    ? differenceInMinutes(new Date(), new Date(latestBlock))
    : 0;

  const isExplorerProcessorBehind = explorerDifference >= LAG_THRESHOLD_MINUTES;

  const lagMessage =
    (isExplorerProcessorBehind || isLpProcessorBehind) &&
    `Indexing is currently experiencing lag. Updates are delayed by ${
      explorerDifference || lpDifference
    } min.`;

  const message = data?.banner?.nodes[0]?.message ?? lagMessage;
  const type = data?.banner?.nodes[0]?.type || (lagMessage && 'WARNING');

  if (!message || message === '' || !type) return null;

  const displayedMessage = message.endsWith('.') ? message : `${message}.`;

  const icon = {
    INFO: <InformationIcon />,
    WARNING: <WarningTriangleIcon />,
    SUCCESS: <SparklesIcon />,
  } as const;
  const style = {
    INFO: 'text-cf-blue-2 bg-cf-blue-5',
    WARNING: 'text-cf-orange-1 bg-cf-orange-4',
    SUCCESS: 'text-cf-green-1 bg-cf-',
  };
  const linkColor = {
    INFO: 'blue',
    WARNING: 'orange',
    SUCCESS: 'green',
  } as const;

  return (
    <div
      ref={(el) => {
        if (el) {
          document.documentElement.style.setProperty('--banner-height', `${el.clientHeight}px`);
        } else {
          document.documentElement.style.setProperty('--banner-height', '0px');
        }
      }}
      className={classNames(
        'fixed top-0 z-10 flex w-full items-center justify-center py-[5px] text-center text-12',
        style[type],
      )}
    >
      <div className="container flex items-center justify-center space-x-1">
        <div>{icon[type]}</div>
        <div>
          {displayedMessage} Head to{' '}
          <Link
            href={chainflipLinks.discord}
            target="_blank"
            underline
            color={linkColor[type]}
            className="inline"
          >
            our discord
          </Link>{' '}
          for more updates
        </div>
      </div>
    </div>
  );
}
