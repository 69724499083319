import { gql } from '../generated/lp';

export const GetLpEarnedFeeAggregates = gql(/* GraphQL */ `
  query getLpEarnedFeeAggregates(
    $idSs58: String!
    $minTimestamp: Datetime!
    $maxTimestamp: Datetime!
    $limitOrderGroupBy: [LimitOrderFillGroupBy!]!
    $rangeOrderGroupBy: [RangeOrderFillGroupBy!]!
    $boostShareGroupBy: [BoostShareGroupBy!]!
  ) {
    account: accountByIdSs58(idSs58: $idSs58) {
      limitOrderFees: limitOrderFillsByLiquidityProviderId(
        filter: {
          and: [
            { blockTimestamp: { greaterThanOrEqualTo: $minTimestamp } }
            { blockTimestamp: { lessThanOrEqualTo: $maxTimestamp } }
          ]
        }
        orderBy: BLOCK_TIMESTAMP_DESC
      ) {
        groupedAggregates(groupBy: $limitOrderGroupBy) {
          keys
          sum {
            feeUsd: feesEarnedValueUsd
          }
        }
      }
      rangeOrderFees: rangeOrderFillsByLiquidityProviderId(
        filter: {
          and: [
            { blockTimestamp: { greaterThanOrEqualTo: $minTimestamp } }
            { blockTimestamp: { lessThanOrEqualTo: $maxTimestamp } }
          ]
        }
        orderBy: BLOCK_TIMESTAMP_DESC
      ) {
        groupedAggregates(groupBy: $rangeOrderGroupBy) {
          keys
          sum {
            baseFeeUsd: baseFeesEarnedValueUsd
            quoteFeeUsd: quoteFeesEarnedValueUsd
          }
        }
      }
      boostFees: boostSharesByLiquidityProviderId(
        filter: {
          and: [
            { executedAtTimestamp: { greaterThanOrEqualTo: $minTimestamp } }
            { executedAtTimestamp: { lessThanOrEqualTo: $maxTimestamp } }
          ]
        }
        orderBy: EXECUTED_AT_TIMESTAMP_DESC
      ) {
        groupedAggregates(groupBy: $boostShareGroupBy) {
          keys
          sum {
            feeUsd
          }
        }
      }
    }
  }
`);

export const getAllLpsTotalFeesAndVolumeQuery = gql(/* GraphQL */ `
  query getAllLpsTotalFeesAndVolumeQuery {
    limitOrders: allLimitOrderFills {
      aggregates {
        sum {
          feesEarnedValueUsd
          filledAmountValueUsd
        }
      }
    }
    rangeOrders: allRangeOrderFills {
      aggregates {
        sum {
          baseFeesEarnedValueUsd
          baseFilledAmountValueUsd
          quoteFeesEarnedValueUsd
          quoteFilledAmountValueUsd
        }
      }
    }
  }
`);

export const getLpsFeesAndVolumeQuery = gql(/* GraphQL */ `
  query getLpsFeesAndVolumeQuery($idSs58: String) {
    accounts: allAccounts(condition: { idSs58: $idSs58 }) {
      nodes {
        idSs58
        limitOrders: limitOrderFillsByLiquidityProviderId {
          aggregates {
            sum {
              filledAmountValueUsd
              feesEarnedValueUsd
            }
          }
        }
        rangeOrders: rangeOrderFillsByLiquidityProviderId {
          aggregates {
            sum {
              quoteFeesEarnedValueUsd
              quoteFilledAmountValueUsd
              baseFilledAmountValueUsd
              baseFeesEarnedValueUsd
            }
          }
        }
      }
    }
  }
`);
